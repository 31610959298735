import React from "react"
import { Paper, Stack, Button } from "@mui/material"

export default function ScheduleActionPanel(props: {
    onClickFullscreenHorizontal: Function,
    onClickFullscreenVertical: Function,
    sx?: object
}) {

    return (
        <Paper sx={props.sx}>
            <Stack direction="row" justifyContent="center" spacing={1} padding={2}>
               <Button
                   variant="contained"
                   onClick={() => props.onClickFullscreenHorizontal()}
               >
                   Horizontal Mission Board Fullscreen
               </Button>
               <Button
                   variant="contained"
                   onClick={() => props.onClickFullscreenVertical()}
               >
                   Vertical Mission Board Fullscreen
               </Button>
            </Stack>
        </Paper>
    )
}