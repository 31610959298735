import React from "react"
import dayjs from "dayjs"
import {
    Stack,
    Paper,
    Typography,
    Divider,
    LinearProgress
} from "@mui/material"
import { Mission } from "../../../classes/Mission"
import Box from "@mui/material/Box";

function MissionBoardItem(props: {
    missionObject: Mission,
    verticalMode: boolean
}) {

    const currentTime = dayjs();
    const timeStartScheduled = props.missionObject.timeStartScheduled
    const timeStartProjected = props.missionObject.timeStartProjected
    const timeStartActual = props.missionObject.timeStartActual
    const timeFinishScheduled = props.missionObject.timeFinishScheduled
    const timeFinishProjected = props.missionObject.timeFinishProjected
    const timeFinishActual = props.missionObject.timeFinishActual

    // TODO: Move this function to a single one that can be imported.
    function getStatusString() {
        if (props.missionObject.isComplete) {
            return "Complete"
        } else if (props.missionObject.isCanceled) {
            return "Canceled"
        } else if (props.missionObject.isInProgress) {
            return "In Progress"
        } else {
            if (timeStartProjected.isBefore(currentTime)) {
                return "Starting Now"
            } else if (timeStartProjected.isSame(timeStartScheduled) && currentTime.isBefore(timeStartScheduled)) {
                return "On Time"
            } else if (timeStartProjected.isAfter(timeStartScheduled)) {
                return "Delayed"
            } else if (timeStartProjected.isBefore(timeStartScheduled)) {
                return "Starting Early"
            }
        }
    }

    // TODO: Move this function to a single one that can be imported.
    function getStatusColor() {
        if (props.missionObject.isComplete) {
            return "#444444"
        } else if (props.missionObject.isCanceled) {
            return "#770000"
        } else if (props.missionObject.isInProgress) {
            return "#007700"
        } else {
            if (timeStartProjected.isBefore(currentTime)) {
                return "#999900"
            } else if (timeStartProjected.isSame(timeStartScheduled) && currentTime.isBefore(timeStartScheduled)) {
                return "#004477"
            } else if (timeStartProjected.isAfter(timeStartScheduled)) {
                return "#DD4400"
            } else if (timeStartProjected.isBefore(timeStartScheduled)) {
                return "#777700"
            }
        }
    }

    // TODO: Move this function to a single one that can be imported.
    function getRPICString() {
        const rpicAssignments = props.missionObject.operatorAssignments!.filter((assignment) => assignment.isRPIC)

        if (rpicAssignments.length === 1) {
            return rpicAssignments[0].operatorObject!.callsign
        } else if (rpicAssignments.length > 1) {
            return "[Multiple RPIC]"
        }

        return " "
    }

    // TODO: Move this function to a single one that can be imported.
    function getProgressValue() {
        if (props.missionObject.isInProgress) {
            const minutesDuration = timeFinishProjected.diff(timeStartActual)
            const minutesElapsed = dayjs().diff(timeStartActual);

            const progress = (minutesElapsed / minutesDuration) * 100
            if (progress >= 100) {
                return 100
            } else if (progress <= 0) {
                return 0
            } else {
                return progress
            }
        } else if (props.missionObject.isComplete) {
            return 100
        } else if (props.missionObject.isCanceled) {
            return 0
        } else {
            return 0
        }
    }

    const horizontalElement = (
        <Stack direction="row" padding={2} justifyContent="space-between" >
            <Typography variant="h5" sx={{ width: "30%" }}>{props.missionObject.name}</Typography>
            <Divider orientation="vertical" sx={{ height: "100%" }} />
            <Typography
                variant="h6"
                sx={{ width: "10%", textAlign: "center" }}
            >
                {props.missionObject.callsign}
            </Typography>
            <Typography
                variant="h6"
                sx={{ width: "10%", textAlign: "center" }}
            >
                {props.missionObject.missionNumber}
            </Typography>
            <Typography
                variant="h6"
                sx={{ width: "10%", textAlign: "center" }}
            >
                {props.missionObject.assignedOrganizationObject?.symbol}
            </Typography>
            <Typography
                variant="h6"
                sx={{ width: "10%", textAlign: "center" }}
            >
                {getRPICString()}
            </Typography>
            <Typography variant="h6" sx={{ width: "5%", textAlign: "center" }}>
                {timeStartActual === undefined ? timeStartProjected.format("HH:mm") : timeStartActual!.format("HH:mm")}
            </Typography>
            <Box sx={{ width: "8%", position: "relative" }}>
                <Box sx={{
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                    width: "100%"
                }}>
                    <LinearProgress variant="determinate" value={getProgressValue()} />
                </Box>
            </Box>
            <Typography variant="h6" sx={{ width: "5%", textAlign: "center" }}>
                {timeFinishActual === undefined ? timeFinishProjected.format("HH:mm") : timeFinishActual!.format("HH:mm")}
            </Typography>
            <Divider orientation="vertical" sx={{ height: "100%" }} />
            <Paper sx={{ width: "8%", textAlign: "center", backgroundColor: getStatusColor() }}>
                <Typography variant="h6">
                    {getStatusString()}
                </Typography>
            </Paper>
        </Stack>
    )

    const verticalElement = (
        <Stack direction="column" padding={2} spacing={1}>
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="h5">{props.missionObject.name}</Typography>
                <Paper sx={{ width: "200px", textAlign: "center", backgroundColor: getStatusColor() }}>
                    <Typography variant="h6">
                        {getStatusString()}
                    </Typography>
                </Paper>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="h6">
                    {timeStartActual === undefined ? timeStartProjected.format("HH:mm") : timeStartActual!.format("HH:mm")}
                </Typography>
                <Box sx={{ width: "calc(100% - 180px)", position: "relative" }}>
                    <Box sx={{
                        position: "absolute",
                        top: "50%",
                        transform: "translateY(-50%)",
                        width: "100%"
                    }}>
                        <LinearProgress variant="determinate" value={getProgressValue()} />
                    </Box>
                </Box>
                <Typography variant="h6">
                    {timeFinishActual === undefined ? timeFinishProjected.format("HH:mm") : timeFinishActual!.format("HH:mm")}
                </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
                <Typography
                    variant="h6"
                    sx={{ width: "25%", textAlign: "start" }}
                >
                    {props.missionObject.callsign}
                </Typography>
                <Typography
                    variant="h6"
                    sx={{ width: "25%", textAlign: "center" }}
                >
                    {`${getRPICString()}`}
                </Typography>
                <Typography
                    variant="h6"
                    sx={{ width: "25%", textAlign: "center" }}
                >
                    {`${props.missionObject.assignedOrganizationObject?.symbol}`}
                </Typography>
                <Typography
                    variant="h6"
                    sx={{ width: "25%", textAlign: "end" }}
                >
                    {props.missionObject.missionNumber}
                </Typography>
            </Stack>
        </Stack>
    )

    return (<Paper sx={{ width: "100%" }}>{props.verticalMode ? verticalElement : horizontalElement}</Paper>)
}

export default MissionBoardItem