import React from 'react';
import { useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Joi from 'joi';
import { Checkbox, FormControlLabel } from '@mui/material';

function EditUserLayout(props: {
    title: string,
    onSubmit: Function,
    onCancel: Function
}) {

    const [isPendingSubmit, setPendingSubmit] = useState(false);
    const [inputUserName, setInputUserName] = useState("");
    const [inputPassphrase, setInputPassphrase] = useState("");
    const [inputIsAdmin, setInputIsAdmin] = useState(false);
    const [inputIsEnabled, setInputIsEnabled] = useState(false);

    const isUserNameValid = Joi.string().min(1).max(100).required().validate(inputUserName);
    const isPassphraseValid = Joi.string().min(8).required().validate(inputPassphrase);
    const isIsAdminValid = Joi.boolean().validate(inputIsAdmin);
    const isIsEnabledValid = Joi.boolean().validate(inputIsEnabled);
    const isValid = (!isUserNameValid.error && !isPassphraseValid.error && !isIsAdminValid.error && !isIsEnabledValid.error);

    function handleSubmit() {
        setPendingSubmit(true)
        props.onSubmit(inputUserName, inputPassphrase, inputIsAdmin, inputIsEnabled)
    }

    const bottomControls = isPendingSubmit ? (<Typography>Loading...</Typography>) : [(<Button variant='text' onClick={() => props.onCancel()}>Cancel</Button>), (<Button variant='contained' disabled={!isValid} onClick={handleSubmit}>Submit</Button>)];

    return (
        <Stack direction="column" spacing={2}>
            <Typography variant="h6">{props.title}</Typography>
            <TextField
                type="text"
                variant="outlined"
                label="Username"
                disabled={isPendingSubmit}
                value={inputUserName}
                onChange={(e) => setInputUserName(e.target.value)}
            />
            <TextField
                type="password"
                variant="outlined"
                label="Set Passphrase"
                disabled={isPendingSubmit}
                value={inputPassphrase}
                onChange={(e) => setInputPassphrase(e.target.value)}
            />
            <Stack direction="row" spacing={1}>
                <FormControlLabel
                    label="Enabled"
                    control={<Checkbox checked={inputIsEnabled} onChange={(e) => setInputIsEnabled(e.target.checked)} />}
                />
                <FormControlLabel
                    label="Admin"
                    control={<Checkbox checked={inputIsAdmin} onChange={(e) => setInputIsAdmin(e.target.checked)} />}
                />
            </Stack>
            <Stack direction="row" spacing={1} justifyContent="right">{bottomControls}</Stack>
        </Stack>
    );
}

export default EditUserLayout;