import React, { useState, useEffect } from "react"
import dayjs from "dayjs"
import {
    Stack,
    Divider,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from "@mui/material"

import PrimaryLayout from "../../../common/layouts/PrimaryLayout"
import NetworkError from "../../../common/components/NetworkError"
import ScheduleTabbedLayout from "../layouts/ScheduleTabbedLayout"
import ScheduleActionPanel from "../components/ScheduleActionPanel"

import { Mission } from "../../../classes/Mission"

import getMissionsOriginatedByAllMyOffices from "../../../data/mission/getMissionsOriginatedByAllMyOffices"
import getMissionsTaskedToAllMyOrganizations from "../../../data/mission/getMissionsTaskedToAllMyOrganizations"
import getMissionsContainingPendingDeconflictionsAssignedToAllMyOffices
    from "../../../data/mission/getMissionsContainingPendingDeconflictionsAssignedToAllMyOffices"
import getMissionsContainingDeconflictionsAssignedToAllMyOffices
    from "../../../data/mission/getMissionsContainingDeconflictionsAssignedToAllMyOffices"
import {SysInfoBannerParams} from "../../../classes/SysInfoBannerParams";
import {User} from "../../../classes/User";
import getBanner from "../../../data/sysinfo/getBanner";
import self from "../../../data/user/self";
import MissionBoardLayout from "../layouts/MissionBoardLayout";
import SysInfoBannerLayout from "../../../common/layouts/SysInfoBannerLayout";

function Schedule() {
    const [bannerData, setBannerData] = useState<SysInfoBannerParams | undefined>(undefined)
    const [userData, setUserData] = useState<User | undefined>(undefined)

    const [isNetworkErrorSnackbarOpen, setNetworkErrorSnackbarOpen] = useState<boolean>(false)

    const [missionList, setMissionList] = useState<Mission[] | undefined>(undefined)

    const [selectedMissionListFilter, setMissionListFilter] = useState<number>(1)
    const [selectedMissionListTime, setMissionListTime] = useState<number>(1)

    const [fullscreenDisplay, setFullscreenDisplay] = useState<number>(0)

    function updateMissionList(m: Mission[]) {
        m.sort((a: Mission, b: Mission) => {
            if (a.timeStartProjected.isAfter(b.timeStartProjected)) {
                return 1
            } else if (a.timeStartProjected.isBefore(b.timeStartProjected)) {
                return -1
            }

            return 0
        })

        setMissionList(m)
    }

    function reloadData() {
        const beginToday = dayjs().startOf("day").format()
        const endToday = dayjs().endOf("day").format()

        // TODO: Change "Today" to the 48-hour window surrounding now.
        // TODO: Or "Starting within 24 hours, Ongoing, etc.
        // TODO: Show missions that are not drafts only.
        if (selectedMissionListFilter === 1) {
            if (selectedMissionListTime === 1) {
                getMissionsOriginatedByAllMyOffices(endToday, beginToday, endToday, beginToday, false)
                    .then((result) => updateMissionList(result))
                    .catch((err) => {
                        console.error(err)
                        setNetworkErrorSnackbarOpen(true)
                    })
            } else if (selectedMissionListTime === 2) {
                getMissionsOriginatedByAllMyOffices(undefined, endToday, undefined, undefined,false)
                    .then((result) => updateMissionList(result))
                    .catch((err) => {
                        console.error(err)
                        setNetworkErrorSnackbarOpen(true)
                    })
            } else if (selectedMissionListTime === 3) {
                getMissionsOriginatedByAllMyOffices(undefined, undefined, beginToday, undefined, false)
                    .then((result) => updateMissionList(result))
                    .catch((err) => {
                        console.error(err)
                        setNetworkErrorSnackbarOpen(true)
                    })
            } else if (selectedMissionListTime === 4) {
                getMissionsOriginatedByAllMyOffices(undefined, undefined, undefined, undefined, false, false, false, false)
                    .then((result) => updateMissionList(result))
                    .catch((err) => {
                        console.log(err)
                        setNetworkErrorSnackbarOpen(true)
                    })
            } else if (selectedMissionListTime === 5) {
                getMissionsOriginatedByAllMyOffices(undefined, undefined, undefined, undefined, false, false, false, true)
                    .then((result) => updateMissionList(result))
                    .catch((err) => {
                        console.log(err)
                        setNetworkErrorSnackbarOpen(true)
                    })
            } else if (selectedMissionListTime === 6) {
                getMissionsOriginatedByAllMyOffices(undefined, undefined, undefined, undefined, true, false, false, false)
                    .then((result) => updateMissionList(result))
                    .catch((err) => {
                        console.log(err)
                        setNetworkErrorSnackbarOpen(true)
                    })
            }
        } else if (selectedMissionListFilter === 2) {
            if (selectedMissionListTime === 1) {
                getMissionsTaskedToAllMyOrganizations(endToday, beginToday, endToday, beginToday, false)
                    .then((result) => updateMissionList(result))
                    .catch((err) => {
                        console.error(err)
                        setNetworkErrorSnackbarOpen(true)
                    })
            } else if (selectedMissionListTime === 2) {
                getMissionsTaskedToAllMyOrganizations(undefined, endToday, undefined, undefined, false)
                    .then((result) => updateMissionList(result))
                    .catch((err) => {
                        console.error(err)
                        setNetworkErrorSnackbarOpen(true)
                    })
            } else if (selectedMissionListTime === 3) {
                getMissionsTaskedToAllMyOrganizations(undefined, undefined, beginToday, undefined, false)
                    .then((result) => updateMissionList(result))
                    .catch((err) => {
                        console.error(err)
                        setNetworkErrorSnackbarOpen(true)
                    })
            } else if (selectedMissionListTime === 4) {
                getMissionsTaskedToAllMyOrganizations(undefined, undefined, undefined, undefined, false, false, false, false)
                    .then((result) => updateMissionList(result))
                    .catch((err) => {
                        console.log(err)
                        setNetworkErrorSnackbarOpen(true)
                    })
            } else if (selectedMissionListTime === 5) {
                getMissionsTaskedToAllMyOrganizations(undefined, undefined, undefined, undefined, false, false, false, true)
                    .then((result) => updateMissionList(result))
                    .catch((err) => {
                        console.log(err)
                        setNetworkErrorSnackbarOpen(true)
                    })
            }
        } else if (selectedMissionListFilter === 3) {
            if (selectedMissionListTime === 1) {
                getMissionsContainingPendingDeconflictionsAssignedToAllMyOffices()
                    .then((result) => updateMissionList(result))
                    .catch((err) => {
                        console.error(err)
                        setNetworkErrorSnackbarOpen(true)
                    })
            } else if (selectedMissionListTime === 2) {
                getMissionsContainingDeconflictionsAssignedToAllMyOffices()
                    .then((result) => updateMissionList(result))
                    .catch((err) => {
                        console.error(err)
                        setNetworkErrorSnackbarOpen(true)
                    })
            }
        }
    }

    useEffect(() => {
        if (bannerData === undefined) {
            getBanner()
                .then((d) => setBannerData(d))
                .catch((err) => {
                    console.log(err)
                    setNetworkErrorSnackbarOpen(true)
                })
        }

        if (userData === undefined) {
            self()
                .then((d) => setUserData(d))
                .catch((err) => {
                    console.log(err)
                    setNetworkErrorSnackbarOpen(true)
                })
        }
    }, [])

    useEffect(() => {
        reloadData()
        const intervalId = window.setInterval(reloadData, 10000)

        return () => window.clearInterval(intervalId)
    }, [userData, selectedMissionListFilter, selectedMissionListTime])

    if (bannerData === undefined || userData === undefined) {
        return (
            <NetworkError
                open={isNetworkErrorSnackbarOpen}
                onClose={() => setNetworkErrorSnackbarOpen(false)}
            />
        ) // TODO: Make this into a loading page for all pages.
    }

    function filterSelected(filterIndex: number) {
        setMissionListFilter(filterIndex)
        setMissionList(undefined)
    }

    function timeSelected(filterIndex: number) {
        setMissionListTime(filterIndex)
        setMissionList(undefined)
    }

    function getStatusFilter() {
        if (selectedMissionListFilter === 1) {
            return [
                (<MenuItem value={1}>Today</MenuItem>),
                (<MenuItem value={2}>Future</MenuItem>),
                (<MenuItem value={3}>Past</MenuItem>),
                (<MenuItem value={4}>Upcoming</MenuItem>),
                (<MenuItem value={5}>Ongoing</MenuItem>)
            ]
        } else if (selectedMissionListFilter === 2) {
            return [
                (<MenuItem value={1}>Today</MenuItem>),
                (<MenuItem value={2}>Future</MenuItem>),
                (<MenuItem value={3}>Past</MenuItem>),
                (<MenuItem value={4}>Upcoming</MenuItem>),
                (<MenuItem value={5}>Ongoing</MenuItem>)
            ]
        } else if (selectedMissionListFilter === 3) {
            return [
                (<MenuItem value={1}>Pending</MenuItem>),
                (<MenuItem value={2}>All</MenuItem>)
            ]
        }
    }

    document.addEventListener("fullscreenchange", (event) => {
        if (!document.fullscreenElement) {
            setFullscreenDisplay(0)
        }
    })

    function handleHorizontalFullscreen() {
        setFullscreenDisplay(1)
        document.body.requestFullscreen()
    }

    function handleVerticalFullscreen() {
        setFullscreenDisplay(2)
        document.body.requestFullscreen()
    }

    function handleChartFullscreen() {
        setFullscreenDisplay(3)
        document.body.requestFullscreen()
    }

    function content() {
        if (missionList === undefined) {
            return null
        } else {
            return (
                <Stack direction="column" spacing={1} padding={1}>
                    <Stack direction="row" spacing={1}>
                        <FormControl fullWidth sx={{ width: "65%" }}>
                            <InputLabel id="mission-filter-select">Select Filter</InputLabel>
                            <Select
                                labelId="mission-filter-select"
                                label="Select Filter"
                                value={selectedMissionListFilter}
                                onChange={(event) => filterSelected(event.target.value as number)}
                            >
                                <MenuItem value={1}>All Originated By My Offices</MenuItem>
                                <MenuItem value={2}>All Tasked To My Organizations</MenuItem>
                                <MenuItem value={3}>All Containing Deconflictions Assigned To My Offices</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{ width: "35%" }}>
                            <InputLabel id="mission-time-filter-select">Select Time Filter</InputLabel>
                            <Select
                                labelId="mission-time-filter-select"
                                label="Select Time Filter"
                                value={selectedMissionListTime}
                                onChange={(event) => timeSelected(event.target.value as number)}
                            >
                                {getStatusFilter()}
                            </Select>
                        </FormControl>
                    </Stack>
                    <ScheduleActionPanel
                        onClickFullscreenHorizontal={handleHorizontalFullscreen}
                        onClickFullscreenVertical={handleVerticalFullscreen}
                    />
                    <Divider />
                    <ScheduleTabbedLayout missionList={missionList} />
                </Stack>
            )
        }
    }

    if (fullscreenDisplay === 0) {
        return (
            <PrimaryLayout
                sysInfoBanner={bannerData}
                userData={userData}
                navId="schedule"
                title="Schedule"
                content={content()}
                modals={[
                    (
                        <NetworkError
                            open={isNetworkErrorSnackbarOpen}
                            onClose={() => setNetworkErrorSnackbarOpen(false)}
                        />
                    )
                ]}
            />
        )
    } else if (fullscreenDisplay === 1) {
        return (
            <SysInfoBannerLayout title="Schedule" sysInfoBanner={bannerData}>
                <MissionBoardLayout missionList={missionList!} verticalMode={false} />
            </SysInfoBannerLayout>
        )
    } else if (fullscreenDisplay === 2) {
        return (
            <SysInfoBannerLayout title="Schedule" sysInfoBanner={bannerData}>
                <MissionBoardLayout missionList={missionList!} verticalMode={true} />
            </SysInfoBannerLayout>
        )
    } else {
        return null
    }
}

export default Schedule