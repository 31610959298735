import React from "react"
import {Stack, Typography} from "@mui/material"

import { Mission } from "../../../classes/Mission"

import MissionNoteInputPanel from "../components/MissionNoteInputPanel"
import MissionBatchSelectionTimelineActionPanel from "../components/MissionBatchSelectionTimelineActionPanel"

export default function MissionBatchSelectionLayout(props: {
    missionList: Mission[],
    onClickConfirmStart: Function,
    onClickConfirmCompletion: Function,
    onClickCancel: Function,
    onSubmitNote: Function
    sx?: object
}) {

    var mayNotModify = false

    props.missionList.forEach((mission) => {
        if (!mission.userPermissions?.modify) {
            mayNotModify = true
        }
    })

    if (mayNotModify) {
        return (<Typography sx={props.sx}>Some of the selected missions may not be modified.</Typography>)
    }

    return (
        <Stack direction="column" spacing={1} sx={props.sx}>
            <MissionBatchSelectionTimelineActionPanel
                missionList={props.missionList}
                onClickConfirmStart={props.onClickConfirmStart}
                onClickConfirmCompletion={props.onClickConfirmCompletion}
                onClickCancel={props.onClickCancel}
                sx={{ width: "100%" }}
            />
            <MissionNoteInputPanel
                onSubmit={props.onSubmitNote}
                sx={{ width: "100%" }}
            />
        </Stack>
    )
}