import React, {ReactNode} from "react"
import {
    Box
} from "@mui/material"
import SysInfoBanner from "../components/SysInfoBanner"

import { SysInfoBannerParams } from "../../classes/SysInfoBannerParams"

export default function SysInfoBannerLayout(props: {
    title: string,
    sysInfoBanner: SysInfoBannerParams,
    children?: ReactNode
}) {
    document.title = "SAMS - " + props.title

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100vw',
                height: '100vh'
            }}
        >
            <SysInfoBanner data={props.sysInfoBanner} />
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    overflow: 'scroll'
                }}
            >
                {props.children}
            </Box>
            <SysInfoBanner data={props.sysInfoBanner} />
        </Box>
    )
}