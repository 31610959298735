import React from "react"
import {
    Paper,
    Stack,
    Button
} from "@mui/material"
import { Mission } from "../../../classes/Mission"

export default function MissionBatchSelectionTimelineActionPanel(props: {
    missionList: Mission[],
    onClickConfirmStart: Function,
    onClickConfirmCompletion: Function,
    onClickCancel: Function,
    sx?: object
}) {

    function isConfirmStartDisabled(): boolean {
        var isDisabled = false

        props.missionList.forEach((missionData) => {
            if (missionData.isComplete || missionData.isCanceled || missionData.isInProgress || missionData.isDraft) {
                isDisabled = true
            }
        })

        return isDisabled
    }

    function isConfirmCompleteDisabled(): boolean {
        var isDisabled = false

        props.missionList.forEach((missionData) => {
            if (missionData.isComplete || missionData.isCanceled || !missionData.isInProgress || missionData.isDraft) {
                isDisabled = true
            }
        })

        return isDisabled
    }

    function isCancelDisabled(): boolean {
        var isDisabled = false

        props.missionList.forEach((missionData) => {
            if (missionData.isComplete || missionData.isCanceled || missionData.isDraft) {
                isDisabled = true
            }
        })

        return isDisabled
    }

    return (
        <Paper sx={props.sx}>
            <Stack direction="row" justifyContent="center" spacing={1} padding={2}>
                <Button
                    variant="contained"
                    disabled={isCancelDisabled()}
                    onClick={() => props.onClickCancel()}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    disabled={isConfirmStartDisabled()}
                    onClick={() => props.onClickConfirmStart()}
                >
                    Confirm Start
                </Button>
                <Button
                    variant="contained"
                    disabled={isConfirmCompleteDisabled()}
                    onClick={() => props.onClickConfirmCompletion()}
                >
                    Confirm Completion
                </Button>
            </Stack>
        </Paper>
    )
}