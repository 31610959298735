import React from "react"
import {
    Stack,
    Typography
} from "@mui/material"

import MissionBoardItem from "../components/MissionBoardItem"
import { Mission } from "../../../classes/Mission"

function MissionBoardLayout(props: { missionList: Mission[], verticalMode: boolean }) {

    const missionItems = props.missionList.map((m: any) => (
        <MissionBoardItem missionObject={m} verticalMode={props.verticalMode} />
    ))

    const horizontalColumns = (
        <Stack direction="row" padding={2} justifyContent="space-between" width="100%">
            <Typography variant="body1" sx={{ width: "30%" }}>Description</Typography>
            <Typography variant="body1" sx={{ width: "10%", textAlign: "center" }}>Callsign</Typography>
            <Typography variant="body1" sx={{ width: "10%", textAlign: "center" }}>Mission Number</Typography>
            <Typography variant="body1" sx={{ width: "10%", textAlign: "center" }}>Organization</Typography>
            <Typography variant="body1" sx={{ width: "10%", textAlign: "center" }}>RPIC</Typography>
            <Typography variant="body1" sx={{ width: "5%", textAlign: "center" }}>Start Time</Typography>
            <Typography variant="body1" sx={{ width: "8%", textAlign: "center" }}></Typography>
            <Typography variant="body1" sx={{ width: "5%", textAlign: "center" }}>Finish Time</Typography>
            <Typography variant="body1" sx={{ width: "8%", textAlign: "center" }}>Status</Typography>
        </Stack>
    )

    return (
        <Stack direction="column" spacing={1} padding={1} overflow="scroll">
            {props.verticalMode ? null : horizontalColumns}
            {missionItems}
        </Stack>
    )
}

export default MissionBoardLayout