import React from "react"
import { useState } from "react"
import { useEffect, useLayoutEffect } from "react"
import { List, Typography, Stack, Button, FormControl, InputLabel, Select, MenuItem } from "@mui/material"
import NewIcon from "@mui/icons-material/Add"
import PrimaryLayout from "../../../common/layouts/PrimaryLayout"
import ListContentView from "../../../common/layouts/ListContentView"
import MissionTabbedLayout from "../layouts/MissionTabbedLayout"
import MissionListItem from "../components/MissionListItem"
import NewMissionModal from "../modals/NewMissionModal"

import dayjs from "dayjs"

import getMissionsOriginatedByAllMyOffices from "../../../data/mission/getMissionsOriginatedByAllMyOffices"
import getMissionsTaskedToAllMyOrganizations from "../../../data/mission/getMissionsTaskedToAllMyOrganizations"
import createMission from "../../../data/mission/createMission"
import NetworkError from "../../../common/components/NetworkError"
import Divider from "@mui/material/Divider"
import getMissionsContainingDeconflictionsAssignedToAllMyOffices
    from "../../../data/mission/getMissionsContainingDeconflictionsAssignedToAllMyOffices"
import getMissionsContainingPendingDeconflictionsAssignedToAllMyOffices
    from "../../../data/mission/getMissionsContainingPendingDeconflictionsAssignedToAllMyOffices"
import {Mission} from "../../../classes/Mission";
import getMissions from "../../../data/mission/getMissions";
import getBanner from "../../../data/sysinfo/getBanner";
import self from "../../../data/user/self"

import { SysInfoBannerParams } from "../../../classes/SysInfoBannerParams"
import { User } from "../../../classes/User"
import {Deselect, SelectAll} from "@mui/icons-material";
import MissionBatchSelectionLayout from "../layouts/MissionBatchSelectionLayout";
import {filter} from "cypress/types/minimatch";
import startMissionById from "../../../data/mission/startMissionById";
import completeMissionById from "../../../data/mission/completeMissionById";
import cancelMissionById from "../../../data/mission/cancelMissionById";
import addNoteToMissionById from "../../../data/mission/addNoteToMissionById";
import {MissionNote} from "../../../classes/MissionNote";

function Missions() {
    const [bannerData, setBannerData] = useState<SysInfoBannerParams | undefined>(undefined)
    const [userData, setUserData] = useState<User | undefined>(undefined)

    const [isNetworkErrorSnackbarOpen, setNetworkErrorSnackbarOpen] = useState<boolean>(false)
    const [isCreateMissionModalOpen, setCreateMissionModalOpen] = useState<boolean>(false)

    const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0)
    const [selectedMissionListFilter, setMissionListFilter] = useState<number>(1)
    const [selectedMissionListTime, setMissionListTime] = useState<number>(1)

    const [isSelectionModeActive, setSelectionModeActive] = useState<boolean>(false)
    const [selectedMissions, setSelectedMissions] = useState<number[]>([])

    const [missionList, setMissionList] = useState<Mission[] | undefined>(undefined)
    const [selectedMissionId, setSelectedMissionId] = useState<number | null>(null)
    const [selectedMissionObject, setSelectedMissionObject] = useState<Mission | undefined>(undefined)

    function fetchMissions() {
        const beginToday = dayjs().startOf("day").format()
        const endToday = dayjs().endOf("day").format()

        // TODO: Change "Today" to the 48-hour window surrounding now.
        // TODO: Or "Starting within 24 hours, Ongoing, etc.
        if (selectedMissionListFilter === 1) {
            if (selectedMissionListTime === 1) {
                getMissionsOriginatedByAllMyOffices(endToday, beginToday, endToday, beginToday)
                    .then((result) => setMissionList(result))
                    .catch((err) => {
                        console.log(err)
                        setNetworkErrorSnackbarOpen(true)
                    })
            } else if (selectedMissionListTime === 2) {
                getMissionsOriginatedByAllMyOffices(undefined, endToday)
                    .then((result) => setMissionList(result))
                    .catch((err) => {
                        console.log(err)
                        setNetworkErrorSnackbarOpen(true)
                    })
            } else if (selectedMissionListTime === 3) {
                getMissionsOriginatedByAllMyOffices(undefined, undefined, beginToday, undefined)
                    .then((result) => setMissionList(result))
                    .catch((err) => {
                        console.log(err)
                        setNetworkErrorSnackbarOpen(true)
                    })
            } else if (selectedMissionListTime === 4) {
                getMissionsOriginatedByAllMyOffices(undefined, undefined, undefined, undefined, false, false, false, false)
                    .then((result) => setMissionList(result))
                    .catch((err) => {
                        console.log(err)
                        setNetworkErrorSnackbarOpen(true)
                    })
            } else if (selectedMissionListTime === 5) {
                getMissionsOriginatedByAllMyOffices(undefined, undefined, undefined, undefined, false, false, false, true)
                    .then((result) => setMissionList(result))
                    .catch((err) => {
                        console.log(err)
                        setNetworkErrorSnackbarOpen(true)
                    })
            } else if (selectedMissionListTime === 6) {
                getMissionsOriginatedByAllMyOffices(undefined, undefined, undefined, undefined, true, false, false, false)
                    .then((result) => setMissionList(result))
                    .catch((err) => {
                        console.log(err)
                        setNetworkErrorSnackbarOpen(true)
                    })
            }
        } else if (selectedMissionListFilter === 2) {
            if (selectedMissionListTime === 1) {
                getMissionsTaskedToAllMyOrganizations(endToday, beginToday, endToday, beginToday)
                    .then((result) => setMissionList(result))
                    .catch((err) => {
                        console.log(err)
                        setNetworkErrorSnackbarOpen(true)
                    })
            } else if (selectedMissionListTime === 2) {
                getMissionsTaskedToAllMyOrganizations(undefined, endToday)
                    .then((result) => setMissionList(result))
                    .catch((err) => {
                        console.log(err)
                        setNetworkErrorSnackbarOpen(true)
                    })
            } else if (selectedMissionListTime === 3) {
                getMissionsTaskedToAllMyOrganizations(undefined, undefined, beginToday, undefined)
                    .then((result) => setMissionList(result))
                    .catch((err) => {
                        console.log(err)
                        setNetworkErrorSnackbarOpen(true)
                    })
            } else if (selectedMissionListTime === 4) {
                getMissionsTaskedToAllMyOrganizations(undefined, undefined, undefined, undefined, false, false, false, false)
                    .then((result) => setMissionList(result))
                    .catch((err) => {
                        console.log(err)
                        setNetworkErrorSnackbarOpen(true)
                    })
            } else if (selectedMissionListTime === 5) {
                getMissionsTaskedToAllMyOrganizations(undefined, undefined, undefined, undefined, false, false, false, true)
                    .then((result) => setMissionList(result))
                    .catch((err) => {
                        console.log(err)
                        setNetworkErrorSnackbarOpen(true)
                    })
            }
        } else if (selectedMissionListFilter === 3) {
            if (selectedMissionListTime === 1) {
                getMissionsContainingPendingDeconflictionsAssignedToAllMyOffices()
                    .then((result) => setMissionList(result))
                    .catch((err) => {
                        console.log(err)
                        setNetworkErrorSnackbarOpen(true)
                    })
            } else if (selectedMissionListTime === 2) {
                getMissionsContainingDeconflictionsAssignedToAllMyOffices()
                    .then((result) => setMissionList(result))
                    .catch((err) => {
                        console.log(err)
                        setNetworkErrorSnackbarOpen(true)
                    })
            }
        }
    }

    useEffect(() => {
        if (bannerData === undefined) {
            getBanner()
                .then((d) => setBannerData(d))
                .catch((err) => {
                    console.log(err)
                    setNetworkErrorSnackbarOpen(true)
                })
        }

        if (userData === undefined) {
            self()
                .then((d) => setUserData(d))
                .catch((err) => {
                    console.log(err)
                    setNetworkErrorSnackbarOpen(true)
                })
        }
    }, [])

    useEffect(() => {
        if (userData !== undefined) {
            fetchMissions()
            const intervalId = window.setInterval(fetchMissions, 45000)
            return () => window.clearInterval(intervalId)
        }
    }, [userData, selectedMissionListFilter, selectedMissionListTime])

    useLayoutEffect(() => {
        if (selectedMissionId === null) {
            setSelectedMissionObject(undefined)
        } else if (missionList !== undefined) {
            // TODO: If this map function cannot find the mission in the missionList array, fetch it individually using getMissionById(selectedMissionId).
            // TODO: Should this be forEach instead of map?
            var found = false
            missionList.map((m: Mission) => {
                if (m.id == selectedMissionId) {
                    found = true
                    setSelectedMissionObject(m)
                }
            })

            if (!found) {
                setSelectedMissionId(null)
            }
        }
    }, [selectedMissionId, missionList])

    if (bannerData === undefined || userData === undefined) {
        return (
            <NetworkError
                open={isNetworkErrorSnackbarOpen}
                onClose={() => setNetworkErrorSnackbarOpen(false)}
            />
        ) // TODO: Make this into a loading page for all pages.
    }

    function filterSelected(filterIndex: number) {
        setMissionListFilter(filterIndex)
        setMissionList(undefined)
        setSelectedMissionId(null)
        setSelectedMissionObject(undefined)
    }

    function timeSelected(filterIndex: number) {
        setMissionListTime(filterIndex)
        setMissionList(undefined)
        setSelectedMissionId(null)
        setSelectedMissionObject(undefined)
    }

    function getStatusFilter() {
        if (selectedMissionListFilter === 1) {
            return [
                (<MenuItem value={1}>Today</MenuItem>),
                (<MenuItem value={2}>Future</MenuItem>),
                (<MenuItem value={3}>Past</MenuItem>),
                (<MenuItem value={4}>Upcoming</MenuItem>),
                (<MenuItem value={5}>Ongoing</MenuItem>),
                (<MenuItem value={6}>Drafts</MenuItem>)
            ]
        } else if (selectedMissionListFilter === 2) {
            return [
                (<MenuItem value={1}>Today</MenuItem>),
                (<MenuItem value={2}>Future</MenuItem>),
                (<MenuItem value={3}>Past</MenuItem>),
                (<MenuItem value={4}>Upcoming</MenuItem>),
                (<MenuItem value={5}>Ongoing</MenuItem>)
            ]
        } else if (selectedMissionListFilter === 3) {
            return [
                (<MenuItem value={1}>Pending</MenuItem>),
                (<MenuItem value={2}>All</MenuItem>)
            ]
        }
    }

    function getSelectedMissions(): Mission[] {
        if (missionList === undefined) {
            return []
        }

        return selectedMissions.map((missionId) => {
            var missionTmp = new Mission()

            missionList!.forEach((mission) => {
                if (mission.id === missionId) {
                    missionTmp = mission
                }
            })

            return missionTmp
        })
    }

    function isSelected(missionId: number): boolean {
        if (isSelectionModeActive) {
            const filteredMissions = selectedMissions.filter((m) => m == missionId)
            return filteredMissions.length > 0
        } else {
            return selectedMissionId == missionId
        }
    }

    function handleSelect(missionId: number) {
        if (isSelectionModeActive) {
            const filteredMissions = selectedMissions.filter((m) => true)
            filteredMissions.push(missionId)
            setSelectedMissions(filteredMissions)
        } else {
            setSelectedMissionId(missionId)
        }
    }

    function handleDeselect(missionId: number) {
        if (isSelectionModeActive) {
            const filteredMissions = selectedMissions.filter((m) => m != missionId)
            setSelectedMissions(filteredMissions)
        }
    }

    function handleBatchStartMissions() {
        const apiPromises = selectedMissions.map((mId) => startMissionById(mId).catch((err) => setNetworkErrorSnackbarOpen(true)))

        Promise.all(apiPromises)
            .then(() => {
                setSelectionModeActive(false)
                setSelectedMissions([])
            })
            .then(() => fetchMissions())
    }

    function handleBatchCompleteMissions() {
        const apiPromises = selectedMissions.map((mId) => completeMissionById(mId).catch((err) => setNetworkErrorSnackbarOpen(true)))

        Promise.all(apiPromises)
            .then(() => {
                setSelectionModeActive(false)
                setSelectedMissions([])
            })
            .then(() => fetchMissions())
    }

    function handleBatchCancelMissions() {
        const apiPromises = selectedMissions.map((mId) => cancelMissionById(mId).catch((err) => setNetworkErrorSnackbarOpen(true)))

        Promise.all(apiPromises)
            .then(() => {
                setSelectionModeActive(false)
                setSelectedMissions([])
            })
            .then(() => fetchMissions())
    }

    function handleBatchInsertNote(note: string) {
        const apiPromises = selectedMissions.map((mId) => {
            const inputNote = new MissionNote()
            inputNote.missionId = mId
            inputNote.note = note

            return addNoteToMissionById(inputNote).catch((err) => setNetworkErrorSnackbarOpen(true))
        })

        Promise.all(apiPromises)
            .then(() => {
                setSelectionModeActive(false)
                setSelectedMissions([])
            })
            .then(() => fetchMissions())
    }

    const normalButtonBar = (
        <Stack direction="row" spacing={1} justifyContent="space-between">
            <Stack direction="row" spacing={1} justifyContent="start">
                <Button
                    variant="contained" startIcon={<SelectAll />}
                    disabled={missionList === undefined}
                    onClick={() => setSelectionModeActive(true)}
                >
                    Batch Selection
                </Button>
            </Stack>
            <Stack direction="row" spacing={1} justifyContent="end">
                <Button
                    variant="contained"
                    startIcon={<NewIcon />}
                    onClick={() => setCreateMissionModalOpen(true)}
                >
                    New
                </Button>
            </Stack>
        </Stack>
    )

    const batchSelectionButtonBar = (
        <Stack direction="row" spacing={1} justifyContent="space-between">
            <Stack direction="row" spacing={1} justifyContent="start">
                <Button
                    variant="contained"
                    startIcon={<Deselect />}
                    onClick={() => {
                        setSelectionModeActive(false)
                        setSelectedMissions([])
                    }}
                >
                    Finish Batch Selection
                </Button>
            </Stack>
        </Stack>
    )

    // TODO: Disable the "New" button if the user is not assigned to any office which may originate a mission.
    const buttonBar = (
        <Stack direction="column" padding={1} spacing={1}>
            {isSelectionModeActive ? batchSelectionButtonBar : normalButtonBar}
            <Divider />
            <Stack direction="row" spacing={1}>
                <FormControl fullWidth sx={{ width: "65%" }}>
                    <InputLabel id="mission-filter-select">Select Filter</InputLabel>
                    <Select
                        labelId="mission-filter-select"
                        label="Select Filter"
                        value={selectedMissionListFilter}
                        onChange={(event) => filterSelected(event.target.value as number)}
                    >
                        <MenuItem value={1}>All Originated By My Offices</MenuItem>
                        <MenuItem value={2}>All Tasked To My Organizations</MenuItem>
                        <MenuItem value={3}>All Containing Deconflictions Assigned To My Offices</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth sx={{ width: "35%" }}>
                    <InputLabel id="mission-time-filter-select">Select Status Filter</InputLabel>
                    <Select
                        labelId="mission-time-filter-select"
                        label="Select Time Filter"
                        value={selectedMissionListTime}
                        onChange={(event) => timeSelected(event.target.value as number)}
                    >
                        {getStatusFilter()}
                    </Select>
                </FormControl>
            </Stack>
        </Stack>
    )

    function getListMessage() {
        if (missionList === undefined) {
            return "Loading missions..."
        } else if (isSelectionModeActive) {
            return `${selectedMissions.length} missions selected.`
        }

        return `${missionList.length} missions returned.`
    }

    const missionListItems = missionList === undefined ? null : missionList.map((missionObject: any) => (
        <MissionListItem
            selected={isSelected(missionObject.id)}
            onSelect={() => handleSelect(missionObject.id)}
            onDeselect={() => handleDeselect(missionObject.id)}
            isSelectionModeActive={isSelectionModeActive}
            missionObject={missionObject}
        />
    ))

    const list = (<List>{missionListItems}</List>)

    function handleCreateMission(inputMission: Mission) {
        createMission(inputMission)
            .then(() => fetchMissions())
            .then((result: any) => {
                setMissionList(result)
                setCreateMissionModalOpen(false)
            })
            .catch((err) => {
                console.log(err)
                setNetworkErrorSnackbarOpen(true)
            })
    }

    function content() {
        if (isSelectionModeActive) {
            return (
                <MissionBatchSelectionLayout
                    missionList={getSelectedMissions()}
                    onClickConfirmStart={handleBatchStartMissions}
                    onClickConfirmCompletion={handleBatchCompleteMissions}
                    onClickCancel={handleBatchCancelMissions}
                    onSubmitNote={handleBatchInsertNote}
                    sx={{
                        width: "650px",
                        transform: "translate(100px, 250px)"
                    }}
                />
            )
        } else if (selectedMissionId == null) {
            return (<Typography textAlign="center" sx={{ transform: "translateY(250px)" }}>Select a mission to view its data.</Typography>)
        } else if (selectedMissionId !== null && selectedMissionObject === undefined) {
            return (<Typography textAlign="center" sx={{ transform: "translateY(250px)" }}>Loading mission data...</Typography>)
        } else if (selectedMissionId !== null && selectedMissionObject !== undefined) {
            if (selectedMissionId == selectedMissionObject.id) {
                return (
                    <MissionTabbedLayout
                        selectedTab={selectedTabIndex}
                        onChangeTab={(id: number) => setSelectedTabIndex(id)}
                        missionData={selectedMissionObject}
                        onNetworkError={() => setNetworkErrorSnackbarOpen(true)}
                        onRefresh={() => fetchMissions()}
                    />
                )
            }
        }
    }

    return (
        <PrimaryLayout
            sysInfoBanner={bannerData}
            userData={userData}
            navId="missions"
            title="Missions"
            content={
                <ListContentView
                    buttonBar={buttonBar}
                    list={list}
                    content={content()}
                    selectionMessage={getListMessage()}
                />
            }
            modals={[
                (
                    <NewMissionModal
                        isOpen={isCreateMissionModalOpen}
                        onClose={() => setCreateMissionModalOpen(false)}
                        onSubmit={handleCreateMission}
                        onNetworkError={() => setNetworkErrorSnackbarOpen(true)}
                    />
                ),
                (
                    <NetworkError
                        open={isNetworkErrorSnackbarOpen}
                        onClose={() => setNetworkErrorSnackbarOpen(false)}
                    />
                )
            ]}
        />
    )
}

export default Missions