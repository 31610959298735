import React from 'react';
import { Paper, Stack, Typography, Divider, TextField, LinearProgress, Button } from '@mui/material';

import dayjs from 'dayjs';
import {Mission} from "../../../classes/Mission";

function MissionTimelinePanel(props: {
    missionData: Mission,
    onClickReschedule: Function,
    onClickDelay: Function,
    onClickCancel: Function,
    onClickConfirmStart: Function,
    onClickConfirmCompletion: Function,
    sx?: object
}) {

    const currentTime = dayjs();
    const timeStartScheduled = props.missionData.timeStartScheduled
    const timeStartProjected = props.missionData.timeStartProjected
    const timeStartActual = props.missionData.timeStartActual
    const timeFinishScheduled = props.missionData.timeFinishScheduled
    const timeFinishProjected = props.missionData.timeFinishProjected
    const timeFinishActual = props.missionData.timeFinishActual

    // TODO: Move this function to a single one that can be imported.
    function getStatusString() {
        if (props.missionData.isDraft) {
            return "Draft"
        } else if (props.missionData.isComplete) {
            return "Complete"
        } else if (props.missionData.isCanceled) {
            return "Canceled"
        } else if (props.missionData.isInProgress) {
            return "In Progress"
        } else {
            if (timeStartProjected.isBefore(currentTime)) {
                return "Awaiting Start Confirmation"
            } else if (timeStartProjected.isSame(timeStartScheduled) && currentTime.isBefore(timeStartScheduled)) {
                return "On Time"
            } else if (timeStartProjected.isAfter(timeStartScheduled)) {
                return "Delayed"
            } else if (timeStartProjected.isBefore(timeStartScheduled)) {
                return "Starting Early"
            }
        }
    }

    // TODO: Move this function to a single one that can be imported.
    function getProgressValue() {
        if (props.missionData.isInProgress) {
            const minutesDuration = timeFinishProjected.diff(timeStartActual);
            const minutesElapsed = dayjs().diff(timeStartActual);

            const progress = (minutesElapsed / minutesDuration) * 100;
            if (progress >= 100) {
                return 100;
            } else if (progress <= 0) {
                return 0;
            } else {
                return progress;
            }
        } else if (props.missionData.isComplete) {
            return 100;
        } else if (props.missionData.isCanceled) {
            return 0;
        } else {
            return 0;
        }
    }

    function showError(): boolean {
        if (props.missionData.isInProgress) {
            return (!props.missionData.isComplete && !props.missionData.isCanceled && currentTime.isAfter(timeFinishProjected))
        } else {
            return (!props.missionData.isComplete && !props.missionData.isCanceled && currentTime.isAfter(timeStartProjected))
        }
    }

    function getTimingInfo() {
        if (props.missionData.isComplete) {
            return (
                <Stack direction="row" justifyContent="center" spacing={1}>
                    <TextField InputProps={{ readOnly: true }} label="Total Duration" value={`${timeFinishActual!.diff(timeStartActual!, 'minute')} minutes`} sx={{ width: "300px" }} />
                </Stack>
            );
        } else if (props.missionData.isInProgress) {
            return (
                <Stack direction="row" justifyContent="center" spacing={1}>
                    <TextField InputProps={{ readOnly: true }} label="Elapsed" value={`${dayjs().diff(timeStartActual!, 'minute')} minutes`} sx={{ width: "150px" }} />
                    <TextField InputProps={{ readOnly: true }} error={showError()} label="Remaining" value={`${timeFinishProjected.diff(dayjs(), 'minute')} minutes`} sx={{ width: "150px" }} />
                    <TextField InputProps={{ readOnly: true }} label="Total Duration" value={`${timeFinishProjected.diff(timeStartActual!, 'minute')} minutes`} sx={{ width: "150px" }} />
                </Stack>
            );
        } else if (props.missionData.isCanceled || props.missionData.isDraft) {
            return null;
        } else {
            return (
                <Stack direction="row" justifyContent="center" spacing={1}>
                    <TextField InputProps={{ readOnly: true }} error={showError()} label="Time Remaining Until Start" value={`${timeStartProjected.diff(dayjs(), 'minute')} minutes`} sx={{ width: "300px" }} />
                </Stack>
            );
        }
    }

    const buttonBar = (
        <Stack direction="row" spacing={1}>
            <Button
                variant="contained"
                disabled={props.missionData.isComplete || props.missionData.isCanceled || props.missionData.isInProgress}
                onClick={() => props.onClickReschedule()}
            >
                Reschedule
            </Button>
            <Button
                variant="contained"
                disabled={props.missionData.isComplete || props.missionData.isCanceled || props.missionData.isInProgress || props.missionData.isDraft}
                onClick={() => props.onClickDelay()}
            >
                Delay
            </Button>
            <Button
                variant="contained"
                disabled={props.missionData.isComplete || props.missionData.isCanceled || props.missionData.isDraft}
                onClick={() => props.onClickCancel()}
            >
                Cancel
            </Button>
            <Button
                variant="contained"
                disabled={props.missionData.isDraft || props.missionData.isComplete || props.missionData.isCanceled || props.missionData.isInProgress}
                onClick={() => props.onClickConfirmStart()}
            >
                Confirm Start
            </Button>
            <Button
                variant="contained"
                disabled={!props.missionData.isInProgress}
                onClick={() => props.onClickConfirmCompletion()}
            >
                Confirm Completion
            </Button>
        </Stack>
    )

    return (
        <Paper sx={props.sx}>
            <Stack direction="column" spacing={1} padding={2}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant='h5'>Timeline</Typography>
                    {props.missionData.userPermissions!.modify ? buttonBar: null}
                </Stack>
                <Divider />
                <Stack direction="row" justifyContent="space-evenly" spacing={1}>
                    <Stack direction="column" spacing={1}>
                        <TextField InputProps={{ readOnly: true }} label="Scheduled Start Time" value={timeStartScheduled.format('MM/DD/YYYY HH:mm')} sx={{ width: "180px" }} />
                        <TextField InputProps={{ readOnly: true }} label="Projected Start Time" value={timeStartProjected.format('MM/DD/YYYY HH:mm')} sx={{ width: "180px" }} />
                        <TextField InputProps={{ readOnly: true }} disabled={props.missionData.isDraft} label="Actual Start Time" value={timeStartActual === undefined ? "Not Yet Started" : timeStartActual!.format('MM/DD/YYYY HH:mm')} sx={{ width: "180px" }} />
                    </Stack>
                    <Stack direction="column" justifyContent="space-evenly" sx={{ width: "calc(100% - 400px)" }}>
                        <Typography textAlign="center">{getStatusString()}</Typography>
                        <LinearProgress variant="determinate" value={getProgressValue()} />
                        {getTimingInfo()}
                    </Stack>
                    <Stack direction="column" spacing={1}>
                        <TextField InputProps={{ readOnly: true }} label="Scheduled Finish Time" value={timeFinishScheduled.format('MM/DD/YYYY HH:mm')} sx={{ width: "180px" }} />
                        <TextField InputProps={{ readOnly: true }} label="Projected Finish Time" value={timeFinishProjected.format('MM/DD/YYYY HH:mm')} sx={{ width: "180px" }} />
                        <TextField InputProps={{ readOnly: true }} disabled={props.missionData.isDraft} label="Actual Finish Time" value={timeFinishActual === undefined ? "Not Yet Finished" : timeFinishActual!.format('MM/DD/YYYY HH:mm')} sx={{ width: "180px"}} />
                    </Stack>
                </Stack>
            </Stack>
        </Paper>
    );
}

export default MissionTimelinePanel;