import React, { useState } from "react"
import {
    Tab,
    Tabs,
    Box,
    Typography
} from "@mui/material"

import MissionBoardLayout from "./MissionBoardLayout"
import ChartLayout from "./ChartLayout"
import { Mission } from "../../../classes/Mission"

function ScheduleTabbedLayout(props: { missionList: Mission[] }) {
    const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0)

    function selectTab(event: any, newValue: number) {
        setSelectedTabIndex(newValue)
    }

    function renderTab() {
        if (selectedTabIndex === 0) {
            return (<MissionBoardLayout missionList={props.missionList} verticalMode={false} />)
        } else if (selectedTabIndex === 1) {
            return (<MissionBoardLayout missionList={props.missionList} verticalMode={true} />)
        } else if (selectedTabIndex === 2) {
            return (<ChartLayout missionList={props.missionList}/>)
        } else if (selectedTabIndex === 3) {
            return (<Typography>This tab is still under construction.</Typography>)
        }
    }

    return (
        <Box sx={{ width: "100%", height: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={selectedTabIndex} onChange={selectTab} centered variant="scrollable" scrollButtons="auto">
                    <Tab label="Horizontal Mission Board" />
                    <Tab label="Vertical Mission Board" />
                    <Tab label="Chart" />
                </Tabs>
            </Box>
            {renderTab()}
        </Box>
    )
}

export default ScheduleTabbedLayout